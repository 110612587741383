:root {
    --read-only-color: #00000008;
}

.config-option-cell.readonly,
.config-option-cell select:disabled,
.config-option-cell input:disabled {
    background-color: var(--read-only-color);
}

.config-vertical .config-group.options {
    max-width: 1200px;
}



/* Group Layout  */
.config-vertical .config-group-header {
    font-size: larger;
    font-weight: bold;
}

.config-option-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

/* Options Layout  */
.config-option {
    display: grid;
    grid-template-columns: 200px 10fr;
}



.config-label {
    display: flex;
    color: #808080;
}



.config-indicator-bar {
    display: flex;
    justify-content:space-between;    
}

.config-indicator-bar > div{
    width:20px;
}


.config-size-indicator i {
    font-size: 18px;
}


.config-indicator-bar i {
    font-size: 18px;
}

.config-error-indicator {
    color: red;
}
.config-warning-indicator {
    color: orange;
}

.config-change-indicator {
    color: green;
}
.config-required-indicator {
    color: red; 
}


.config-extra-indicator.warning {
    color: orange;
}
.config-extra-indicator.error {
    color: red;
}

.config-option-value-container {
    display: flex;
}

.config-value{
    flex: 1 0 350px;
}


/* Extras Layout  */

.config-extra-container {
    border: 1px solid #00000028
}

.config-extra-header {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 10fr 80px 80px 10fr auto;
    font-weight: bold;
    background-color: #000000b5;
    color: white;
    padding: 5px 0 5px 0;
}

.config-extra-line {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 10fr 80px 80px 10fr auto;
}



.config-extra-actions {
    display: flex;
}



/*Size Layout  */

.config-vertical .config-size {
    display: grid;
    grid-template-columns: 200px 10fr;
}



.config-vertical .config-size-header {
    color: #808080;
    flex: 1 1 100px;
}


.config-vertical .config-size-value-container {
    flex: 10 1 250px;
    display: flex;
}

.config-vertical .config-size-header {
    display: flex;
}


.config-size-indicator {
    display:flex
}

.config-size-indicator > div{
    width: 20px
}


.config-size-value-actions {
    display: flex;
}

/*...............................................*/



.config-single-line-fabric.readonly {
    background-color: var(--read-only-color);
}


.config-single-line-fabric {
    height: 100%;
}

.config-single-line-fabric-fabric.required {
    background-color: rgba(253, 5, 5, 0.12);
    height: 100%;
}


.config-two-line-fabric {
    display: flex;
    gap: 10px;
}

.config-two-line-fabric-fabric {
    flex: 1;
}

.config-two-line-fabric-color {
    flex: 1;
}

.config-single-line-fabric-fabric {
    display: flex;
    gap: 5px;    
}


.config-single-line-fabric-display {
    display: flex;
    gap: 5px;
    flex: 1;
    min-height:32px;
}

.config-single-line-fabric-fabric {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    height:100%;
}

.config-single-line-fabric-supplier {
    flex: 1;
    border-right-style: solid;
    border-right-width: 1px;
    border-color: #808080;
}

.config-single-line-fabric-name {
    flex: 1;
    border-right-style: solid;
    border-right-width: 1px;
    border-color: #808080;
}

.config-single-line-fabric-color {
    flex: 1;
}



.config-single-line-fabric-btn {
    display: flex;
    gap: 1px;
}

.config-single-line-fabric-btn > div {
    padding: 0 5px 0 5px;
}

.config-single-line-fabric-btn > div:first-child {
    opacity: 50%
}


.config-panel-pattern.readonly {
    background-color: var(--read-only-color);
}

.config-panel-pattern {
    height: 100%;
    width: 100%;
    display: flex;
}


.config-panel-pattern .required {
    background-color: rgba(253, 5, 5, 0.12);
}

.config-panel-pattern-display {
    flex: 1;
    height: 100%;
}


.config-picklist {
    display: flex;
    gap: 10px;
}

.config-picklist-picklist {
    flex: 1;
}

.config-picklist-custom {
    flex: 1;
}

.config-value-item-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.config-value-item.selected {
    border-color: black;
    background-color: #00000016
}


.config-value-item {
    display: flex;
    gap: 3px;
    flex-direction: column;
    border: 1px solid #00000054;
}


.config-value-item-header {
    min-width: 150px;
    display: flex; 
    align-items: flex-start; 
}

.config-value-item-header .acc-icon-containter {
    overflow: hidden;
}


.config-value-item-header > div:first-child {
    display: inline-block;
    max-width: 30px;
}

.config-value-item-header > div:first-child i {
    max-width: 30px;
}

.config-value-item-image {
    flex: 0 0 100px;
}

.config-size-single {
    max-width: 150px;
}

.config-value-action.readonly {
    opacity: 0.5;
}

.config-value-action i {
    padding: 5px 5px 9px 5px;
}

.config-value-action {
    color: white;
    background-color: #0d6efd;
    border-end-end-radius: 5px;
    border-start-end-radius: 5px;
}

.config-extra-actions-add.config-value-action {
    border-end-end-radius: 0;
    border-start-end-radius: 0;
}

.config-value.small .config-select {
    border-style: none;
    -webkit-appearance: none;
    appearance: none
}

td .config-value .small {
    height: 100%;
}

.config-size-value-container.small .accentNumberUnbound .k-input-spinner {
    display: none;
    width: 0;
}
.config-size-value-container.small .accentNumberUnbound input {
    padding:0;
    width:50px;
}

.config-size-value-container.small.multi .accentNumberUnbound input {
    padding: 0;
    width: 70px;
}

.config-size-value-container.small.multi {
    display: flex;
    align-items: center;
}

.config-value.small .config-control.config-two-line-fabric {
    min-width: 160px;
}

.config-size-value-container.small .accentComboUnbound button,
.config-value.small .config-control.config-two-line-fabric .accentComboUnbound button,
.config-value.small .config-control.config-picklist .accentComboUnbound button {
    width: 0;
    display: none;
}


.config-size-value-container.small .accentComboUnbound input,
.config-value.small .config-control.config-two-line-fabric .accentComboUnbound input,
.config-value.small .config-control.config-picklist .accentComboUnbound input {
    padding: 0;
}


.config-value.small .config-control.config-numeric .accentNumberUnbound .k-input-spinner {
    display: none;
    width: 0;
}

.config-value.small .config-control.config-numeric .accentNumberUnbound input {
    padding:0;
}

.config-value.small .config-control.config-text .acc-unbound-txt-ctrl input {
    padding: 0;
}

.config-size-value-container.small .k-input-solid,
.config-value.small .k-input-solid {
    border: 0 !important;
}


.config-select.required {
    background-color: rgba(253, 5, 5, 0.12);
}

.config-select {
    width: 100%;
    height: 38px;
    border-top-style: none;
    border-left-style: none;
    border-right-style: none;
}

.config-popup {
    min-width: 250px;
}


.config-option input,
.config-option select {
    background-color: white;
}


.config-option.readonly input,
.config-option.readonly select {
    background-color: var(--read-only-color);
}

.config-fabric-details-dlg div.row{
    padding-bottom:10px;
}


@media only screen and (max-width: 767px) {
    .config-option {
            display: unset;
            grid-template-columns: unset;
        }

        .config-indicator-bar {
            order: 1;
            flex-direction: row-reverse;
        }

        .config-extra-header {
            display: unset;
            gap: 0px;
            grid-template-columns: unset;
            padding: 5px 0 5px 0;
        }

            .config-extra-header > div {
                font-weight: bold;
                background-color: #000000b5;
                color: white;
            }

        .config-extra-line {
            display: unset;
        }
    }


.option-is-filtered {
    display: none;
}


